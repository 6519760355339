import { NpsEnum } from "../../enum/nps.enum";

export const props = () => ({
  zendeskId: "df3898b2-73f6-4e0d-b96a-734decbe9d9e",
  apiUrl: "https://api-adm-clientes-prod.graodireto.com.br",
  logo: "adm",
  theme: "adm",
  appTitle: "Gestão de Clientes",
  companyLanguage: "default",
  keyCloakConfig: {
    url: "https://auth.graodireto.com.br",
    realm: "adm",
    clientId: "adm-gclients-front",
  },
  production: false,
  firebaseConfig: {
    apiKey: "",
    authDomain: "",
    projectId: "",
    storageBucket: "",
    messagingSenderId: "",
    appId: "",
    measurementId: "",
  },
  hotjarConfig: "",
  nps: {
    api: "https://api.graodireto.com.br/api",
    url: "https://graodireto.com.br/nps",
    key: "109d2ffc-e06b-4c6a-88c2-6a1b9095dc75",
  },
  npsSourceId: NpsEnum.CLIENT_MANAGEMENT_ADM,
});
